import React, {useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import {Card, Container, Row, Col, Badge, CardBody} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import {AiOutlineDown, AiOutlineUp} from 'react-icons/ai';
import moment from 'moment';
import api, {
  appointmentStatusMessages,
  APPOINTMENT_ACCEPTED,
  APPOINTMENT_CANCELED,
  APPOINTMENT_CREATED,
  APPOINTMENT_DATE_CHANGED,
  APPOINTMENT_FINISHED,
  DOCTOR_CHANGED,
  PATIENT_VIDEO_NOSHOW,
} from '../helpers/api';

const CustomPagination = ({outData = [{}], changeDate = ''}) => {
  const [data, setData] = useState([{}]);
  const [pagination, setPagination] = useState();
  const [sort, setSort] = useState(false);
  const navigate = useNavigate();

  const handleChange = () => {
    const newData = outData.filter(
        item => cleanString(item['ScheduleAt'], changeDate));
    if (JSON.stringify(newData) !== JSON.stringify(data)) {
      setData(newData);
      setPagination(buildPagination(newData, navigate, getBadgeClass));
    }
  };

  const cleanString = (string1, string2) => {
    if (typeof string1 !== 'string' && typeof string1 !==
        'number') return false;
    if (typeof string2 !== 'string' && typeof string2 !==
        'number') return false;

    return string1.toString().
        replaceAll(':', '').
        replaceAll('-', '').
        includes(string2.toString().replaceAll('-', '').replaceAll(':', ''));
  };

  const getBadgeClass = (status) => {
    switch (status) {
      case APPOINTMENT_CREATED:
        return 'badge-primary';
      case APPOINTMENT_ACCEPTED:
        return 'badge-info';
      case APPOINTMENT_CANCELED:
        return 'badge-danger';
      case APPOINTMENT_FINISHED:
        return 'badge-success';
      case DOCTOR_CHANGED:
        return 'badge-info';
      case APPOINTMENT_DATE_CHANGED:
        return 'badge-secondary';
      case PATIENT_VIDEO_NOSHOW:
        return 'badge-warning';
      default:
        return 'badge-secondary';
    }
  };

  const toggleSort = () => {
    const sortedData = [...data].sort((a, b) => {
      return sort
          ? new Date(a.ScheduledAt) - new Date(b.ScheduledAt)
          : new Date(b.ScheduledAt) - new Date(a.ScheduledAt);
    });
    setSort(!sort);
    setData(sortedData);
    setPagination(
        buildPagination(sortedData, navigate, getBadgeClass));
  };

  useEffect(() => {
    handleChange();
    const sortedData = [...outData].reverse();
    setData(sortedData);
    setPagination(buildPagination(sortedData, navigate, getBadgeClass));
  }, [outData]);

  return (
      <>
        <Row xs={3}>
          <Col>
            <Button className="bg-transparent border-0 text-black"
                    onClick={toggleSort}>
              Data {sort ? <AiOutlineUp/> : <AiOutlineDown/>}
            </Button>
          </Col>
        </Row>
        {pagination}
      </>
  );
};

const buildPagination = (pageData, navigate, getBadgeClass) => {
  return (
      <>
        {pageData.map((item, index) => {
              if (item.Status !== 1 && item.Status !== 6 && item.Status !== 2) return null;
              return (
                      <Card key={index}
                          className="bg-transparent border-1 border-primary appointment-card g-2"
                          onClick={() => {
                            navigate('/waitingroom', {
                              state: {id: item.Id},
                              replace: true,
                            });
                          }}
                      >
                        <CardBody>
                          <Row className="align-items-center text-primary">
                            <Col md={1} xs={4} className="text-center">
                              <strong>
                                {moment(item.ScheduledAt).format('DD/MM')}
                                <br/>
                              </strong>
                              <small>
                                {moment(item.ScheduledAt).format('HH:mm')}
                              </small>
                            </Col>
                            <Col md={9} xs={8}>
                              <strong>
                                {item.PatientName}
                              </strong>
                            </Col>
                            <Col md={2} xs={12} className="text-center">
                              <Badge className={`badge ${getBadgeClass(
                                  item.Status)}`}>
                                {appointmentStatusMessages[item.Status] || 'Status desconhecido'}
                              </Badge>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
              )
            })}
      </>
  );
};

export default CustomPagination;