import React from 'react';
import {
    Container, Nav, Navbar, NavbarBrand, NavItem, NavLink,
    Col,
    Row
} from "react-bootstrap";

import banner1 from '../assets/images/lp/banner1.jpg';
import logo from '../assets/images/logo-white.png';
import logoToksaude from '../assets/images/lp/partner/toksaude.png';
import fig001 from '../assets/images/lp/teleconsulta.png';
import fig002 from '../assets/images/lp/enfermagem.png';
import fig003 from '../assets/images/lp/monitoramento.png';
import fig004 from '../assets/images/lp/adulto-infantil.png';
import fig005 from '../assets/images/lp/prescricao.png';
import fig006 from '../assets/images/lp/telepsicologia.png';
import fig007 from '../assets/images/lp/agenda.png';
import fig008 from '../assets/images/lp/carencia.png';
import icon01 from '../assets/images/lp/icon/ico-alergia.png';
import icon02 from '../assets/images/lp/icon/ico-psiquiatria.png';
import icon03 from '../assets/images/lp/icon/ico-ginecologia.png';
import icon04 from '../assets/images/lp/icon/ico-dermato.png';
import icon05 from '../assets/images/lp/icon/ico-oftalmo.png';
import icon06 from '../assets/images/lp/icon/ico-neurologia.png';
import icon07 from '../assets/images/lp/icon/ico-pediatria.png';
import icon08 from '../assets/images/lp/icon/ico-cardio.png';
import icon09 from '../assets/images/lp/icon/ico-ginecologia.png';
import icon10 from '../assets/images/lp/icon/ico-otorrino.png';
import icon11 from '../assets/images/lp/icon/ico-endocrino.png'
import partner01 from '../assets/images/lp/partner/drogasil.png';
import partner02 from '../assets/images/lp/partner/pacheco.png';
import partner03 from '../assets/images/lp/partner/saopaulo.png';
import partner04 from '../assets/images/lp/partner/raia.png';
import partner05 from '../assets/images/lp/partner/paguemenos.png';
import partner06 from '../assets/images/lp/partner/extrafarma.png';
import icoEmail from '../assets/images/lp/icon/email.png';
import icoPhone from '../assets/images/lp/icon/ligacao.png';

const sectionFig = {
    beneficios: [
        {label: 'Teleconsulta 24 horas', img: fig001},
        {label: 'Triagem com enfermagem', img: fig002},
        {label: 'Monitoramento pós-teleconsulta', img: fig003},
        {label: 'Adulto e infantil', img: fig004},
    ],
    beneficios2: [
        {label: 'Prescrição 100% digital', img: fig005},
        {label: 'Telepsicologia', img: fig006},
        {label: 'Atendimento rápido', img: fig007},
        {label: 'Sem carência', img: fig008},
    ],
    especialidades: [
        {label: 'Alergia Imunologia', img: icon01},
        {label: 'Psiquiatria', img: icon02},
        {label: 'Ginecologia', img: icon03},
        {label: 'Dermatologia', img: icon04},
        {label: 'Oftalmologia', img: icon05},
        {label: 'Neurologia', img: icon06},
        {label: 'Pediatria', img: icon07},
        {label: 'Cardiologia', img: icon08},
        {label: 'Geriatria', img: icon09},
        {label: 'Otorrinolaringologia', img: icon10},
        {label: 'Endocrinologia', img: icon11},
    ],
    drugstore: [
        {label: 'Drogasil', img: partner01},
        {label: 'Pacheco', img: partner02},
        {label: 'São Paulo', img: partner03},
        {label: 'Droga Raia', img: partner04},
        {label: 'PagueMenos', img: partner05},
        {label: 'ExtraFarma', img: partner06},
    ],
    prices: [
        {product: 'CredBlack Saúde I', daily: 'R$ 0,86', monthly: 'R$ 25,90', life: '1 Titular'},
        {product: 'CredBlack Saúde II', daily: 'R$ 1,23', monthly: 'R$ 36,90', life: '1 Titular + 1 dependente'},
        {product: 'CredBlack Saúde III', daily: 'R$ 1,50', monthly: 'R$ 44,90', life: '1 Titular + 2 dependente'},
        {product: 'CredBlack Saúde IV', daily: 'R$ 1,66', monthly: 'R$ 49,90', life: '1 Titular + 3 dependente'},
    ]
}

const Home = () => {

    return (
        <>
            <Navbar bg="dark" variant="dark" expand="lg">
                <Container>
                    <NavbarBrand href="/">
                        <img src={logo} alt="logo" width="100px"/>
                    </NavbarBrand>
                    <NavItem>
                        <a href="/login" className="btn btn-outline-secondary">FAÇA SUA TELECONSULTA</a>
                    </NavItem>
                </Container>
            </Navbar>
            <div style={{backgroundColor: '#efefef'}}>
                <Container className="d-flex justify-content-center align-items-center py-2">
                    <Nav justified fill>
                        <NavItem>
                            <NavLink href="#beneficios">
                                BENEFÍCIOS
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="#precos">
                                PREÇOS
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="#atendimento">
                                CANAIS DE ATENDIMENTO
                            </NavLink>
                        </NavItem>
                    </Nav>
                </Container>
            </div>
            <div>
                <img src={banner1} className="img-fluid" alt=""/>
            </div>
            <section id="beneficios" style={{backgroundColor: '#efefef'}}>
                <Container className="py-5">
                    <div className="mb-5 w-50 mx-auto text-center">
                        <h2>
                            CONSULTAS MÉDICAS RÁPIDAS E FÁCEIS, ONDE VOCÊ ESTIVER!
                        </h2>
                        <p>CredBlack Saúde é o plano de assistência a saúde para quem tem o Cartão South CredBlack.</p>
                    </div>
                    <Row className="mx-5 g-2">
                        {sectionFig.beneficios.map((item, index) => (
                            <Col key={index} xs={12} sm={2} md={3}>
                                <div
                                    className="d-flex align-items-center gap-2 p-3 border border-1 rounded-4 bg-white">
                                    <img src={item.img} alt="" className="img-fluid" style={{height: '68px'}}/>
                                    <strong className="d-block">{item.label}</strong>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </section>
            <section id="especialidades">
                <Container className="py-5">
                    <div className="w-50 mx-auto text-center">
                        <h2 className="h1">
                            MAIS DE <span style={{fontWeight: 900, color: '#6f0cc5'}}>25</span> ESPECIALIDADES
                        </h2>
                    </div>
                    <div className="w-75 mx-auto text-center">
                        <Row className="mt-4 g-4">
                            {sectionFig.especialidades.map((item, index) => (
                                <Col xs={6} sm={2} md={3}>
                                    <div
                                        className="d-flex flex-column justify-content-center align-items-center gap-2 p-3 rounded-4 shadow shadow-md bg-white">
                                        <img src={item.img} alt="" className="img-fluid" style={{height: '50px'}}/>
                                        <span>{item.label}</span>
                                    </div>
                                </Col>
                            ))}
                            <Col xs={6} sm={2} md={3}
                                 className="d-flex flex-column justify-content-center align-items-center gap-2 p-3 rounded-4 shadow shadow-md bg-white">
                                E muito mais...
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
            <section style={{backgroundColor: '#efefef'}}>
                <Container>
                    <div className="py-5 w-50 mx-auto text-center">
                        <h2 className="h2">
                            <span style={{fontWeight: 900, color: '#6f0cc5'}}>Sorria!</span><br/>
                            Com CredBlack Saúde você concorre a 4 sorteios mensais de R$ 2.500,00*.
                        </h2>
                        <small>*Valor bruto.</small>
                    </div>
                </Container>
            </section>
            <section>
                <Container>
                    <div className="py-5 w-50 mx-auto text-center">
                        <h2 className="h1">
                            Ainda mais Benefícios!
                        </h2>
                    </div>
                    <Row className="mx-5 g-4">
                        {sectionFig.beneficios2.map((item, index) => (
                            <Col xs={6} md={3}>
                                <div
                                    className="d-flex justify-content-between align-items-center flex-column gap-2 py-4 px-2 rounded-4 shadow shadow-md bg-white">
                                    <img src={item.img} alt="" className="img-fluid" style={{height: '68px'}}/>
                                    <strong>{item.label}</strong>
                                </div>
                            </Col>
                        ))}
                    </Row>

                    <div className="py-5 w-50 mx-auto text-center">
                        <h2 className="h1">
                            Descontos em Medicamentos
                        </h2>
                        <p>Até 45% de desconto em medicamentos nas principais drogarias do país.</p>
                    </div>

                    <Row className="mb-5 g-3">
                        {sectionFig.drugstore.map((item, index) => (
                            <Col xs={6} md={2} className="d-flex justify-content-center align-items-center p-4">
                                <img key={index} src={item.img} alt="" className="img-fluid"/>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </section>
            <section id="precos" style={{backgroundColor: '#efefef'}}>
                <Container className="py-5">
                    <div className="mb-4 w-50 mx-auto text-center">
                        <h2 className="h1">
                            CUIDE DA SUA SAÚDE SEM COMPROMETER O ORÇAMENTO!<br/>
                            <span style={{color: '#6f0cc5'}}>CREDBLACK SAÚDE</span>
                        </h2>
                    </div>
                    <div className="w-50 mx-auto text-center">
                        <Row className="g-4">
                            {sectionFig.prices.map((item, index) => (
                                <Col xs={12} md={6}>
                                    <div
                                        className="d-flex justify-content-center align-items-center flex-column gap-4 shadow shadow-sm rounded-5 p-4 bg-white">
                                        <h2 className="h3">{item.product}</h2>
                                        <div className="p-3 border border-1 rounded-5 w-100">
                                            {item.life}
                                        </div>
                                        <div>
                                            <p className="text-success m-0">
                                                <small className="d-block small text-muted">Equivalente a </small>
                                                <span className="h2">
                                                {item.daily}
                                               </span>
                                                <small className="d-inline small text-muted small">/dia</small>
                                            </p>
                                        </div>

                                        <div className="border-top w-100 pt-2">
                                            <p className="text-primary m-0">
                                                <span className="h5">
                                                {item.monthly}
                                                </span>
                                                <small className="d-inline small text-muted">/mês</small>
                                            </p>

                                        </div>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </div>
                </Container>
            </section>
            <section id="atendimento">
                <Container className="py-4">
                    <Row className="g-4">
                        <Col xs={12} md={4}>
                            <h2>Atendimento ao cliente CredBlack Saúde</h2>
                        </Col>
                        <Col xs={12} md={4} className="text-center">
                            <img src={icoPhone} alt="" style={{height: 30}}/>
                            <h4 className="m-0 mt-1">(21) 2342-3795</h4>
                            <small>SAC exclusivo</small>
                        </Col>
                        <Col xs={12} md={4} className="text-center">
                            <img src={icoEmail} alt="" style={{height: 30}}/>
                            <h5 className="mb-0 mt-1">credblacksaude@toksaude.com.br</h5>
                            <small>E-mail exclusivo</small>
                        </Col>
                    </Row>
                </Container>
            </section>
            <footer className="bg-dark text-white">
                <Container className="py-4">
                    <Row>
                        <Col xs={12} md={2}>
                            <a href="https://www.toksaude.com.br/" target="_blank" rel="noreferrer">
                                <img src={logoToksaude} alt="TokSaúde" className="img-fluid w-50"/>
                            </a>
                        </Col>
                        <Col xs={12} md={{offset:6, span:4}} className="text-center d-flex align-items-center">
                            <a href="https://www.toksaude.com.br/regulamento/condicoes-saude.pdf" target="_blank"
                               className="btn btn-link text-white"
                               rel="noreferrer">
                                Condições Gerais.
                            </a>
                            <a href="https://www.toksaude.com.br/regulamento/condicoes-saude.pdf" target="_blank"
                               className="btn btn-link text-white"
                               rel="noreferrer">
                                Regulamento do sorteio.
                            </a>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </>
    );
};

export default Home;
